<template>
    <div class="b2c-page-bg">
        <div class="b2c-pc-header" v-if="pageStep === 2 || pageStep === 3">
            <h2><a @click="pageStep--">
              <feather-icon size="24" icon="ChevronLeftIcon"/>
            </a>{{pageStep === 2 ? 'TRA CỨU THÔNG TIN TRẢ GÓP' : 'GIỎ HÀNG & THANH TOÁN'}}
            </h2>
        </div>
        <div class="order-lookup-page-content" v-if="pageStep === 1 || pageStep === 2">
            <div class="page-lookup-card" v-if="pageStep === 1">
                <div class="text-lookup-tittle">
                    TRA CỨU ĐƠN HÀNG
                </div>
                <div class="lookup-group-input mt-26px">
                    <b-input-group class="search-input-group">
                        <img src="../assets/images/lookup/search.png" class="search-img" alt="" />
                        <b-form-input type="text" placeholder="Nhập mã đơn hàng"></b-form-input>
                    </b-input-group>
                    <button class="btn-lookup" @click="pageStep = 2">
                        TRA CỨU
                    </button>
                </div>
                 <div class="text-lookup-tittle mt-60px">
                    TRA CỨU THÔNG TIN TRẢ GÓP
                </div>
                <div class="lookup-group-input mt-26px">
                    <b-input-group class="search-input-group">
                        <img src="../assets/images/lookup/search.png" class="search-img" alt="" />
                        <b-form-input type="text" placeholder="Nhập CMND/CCCD để tra cứu trông tin trả góp"></b-form-input>
                    </b-input-group>
                    <button class="btn-lookup" @click="pageStep = 2">
                        TRA CỨU
                    </button>
                </div>
            </div>
            <template v-if="pageStep === 2">
                <b-card class="complete card-order-lookup">
                    <div class="col-md-12">
                        <div class="text-next-payment-date text-center">
                            Ngày trả lãi tiếp theo
                        </div>
                        <div class="d-flex justify-content-center gap-8px align-items-center mt-3px cursor-pointer">
                            <span class="text-payment-date">
                                01/04/2023
                            </span>
                            <img src="../assets/images/lookup/arrow.png" alt="" />
                        </div>
                         <div class="text-payment-total text-center mt-10px">
                            548.000đ
                        </div>
                        <b-card class="complete mt-32px">
                            <b-row>
                                <b-col cols="6">
                                    <div class="text-price-tittle">
                                        Số tiền còn lại phải trả
                                    </div>
                                    <div class="text-price mt-5px">
                                        1.640.000đ
                                    </div>
                                </b-col>
                                <b-col cols="6">
                                    <div class="text-price-tittle">
                                        Số tiền hàng tháng phải trả
                                    </div>
                                    <div class="text-price mt-5px">
                                        548.000đ
                                    </div>
                                </b-col>
                            </b-row>
                            <div class="mt-11px progress-bar">
                                <div class="progress-track" style="width: 60%">
                                </div>
                            </div>
                        </b-card>
                        <b-card class="complete mt-28px card-info">
                            <div class="card-info-header">
                                <img src="../assets/images/lookup/user.png" alt="" />
                                Thông tin thuê bao
                            </div>
                            <div class="card-info-content mt-15px">
                                <b-row class="card-info-content-row">
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Số thuê bao
                                        </div>
                                        <div class="value mt-3px">
                                            087xxxx123
                                        </div>
                                    </b-col>
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Số điện thoại liên hệ
                                        </div>
                                        <div class="value mt-3px">
                                            0942xxxx123
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="card-info-content-row mt-10px">
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Họ và tên chủ thuê bao
                                        </div>
                                        <div class="value mt-3px">
                                            Nguyễn Văn A
                                        </div>
                                    </b-col>
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Email
                                        </div>
                                        <div class="value mt-3px">
                                            nguyenvana@gmail.com
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="card-info-content-row mt-10px">
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Ngày sinh
                                        </div>
                                        <div class="value mt-3px">
                                            dd/mm/yyyy
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card>
                        <b-card class="complete mt-28px card-info">
                            <div class="card-info-header">
                                <img src="../assets/images/lookup/i.png" alt="" />
                                Thông tin khoản vay
                            </div>
                            <div class="card-info-content mt-15px">
                                <b-row class="card-info-content-row">
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Mã hợp đồng
                                        </div>
                                        <div class="value mt-3px">
                                            ITEL123456
                                        </div>
                                    </b-col>
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Họ và tên
                                        </div>
                                        <div class="value mt-3px">
                                            Nguyễn Văn A
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="card-info-content-row mt-10px">
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Số tiền dư nợ hiện tại
                                        </div>
                                        <div class="value mt-3px">
                                            1.234.567đ
                                        </div>
                                    </b-col>
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Tổng số tiền đến hạn
                                        </div>
                                        <div class="value mt-3px">
                                            1.234.567đ
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="card-info-content-row mt-10px">
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Ngày ký hợp đồng
                                        </div>
                                        <div class="value mt-3px">
                                            dd/mm/yyyy
                                        </div>
                                    </b-col>
                                    <b-col cols="6" class="p-0">
                                        <div class="tittle">
                                            Ngày trả lãi tiếp theo
                                        </div>
                                        <div class="value mt-3px">
                                            dd/mm/yyyy
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card>
                        <b-card class="complete mt-28px card-info card-info-table">
                            <div class="card-info-header">
                                <img src="../assets/images/lookup/loan.png" alt="" />
                                Khoản vay
                            </div>
                            <div class="card-info-content mt-15px">
                                <table>
                                    <tr>
                                        <th>Kỳ thanh toán</th>
                                        <th>Số tiền thanh toán</th>
                                        <th>Hạn chót thanh toán</th>
                                        <th>Mã hệ thống</th>
                                        <th>Trạng thái</th>
                                    </tr>
                                    <template v-for="(data, idx) in paymentData">
                                        <tr 
                                            :key="idx"
                                            :style="`background: ${data.status === 'DONE' ? '#EBFFF0' : data.status === 'EXPRIED' ? '#FFEBEB' : '#FFF7EB'}`"
                                        >
                                            <td>{{data.period}}</td>
                                            <td>{{data.amount}}đ</td>
                                            <td>{{data.deadline}}</td>
                                            <td>{{data.code}}</td>
                                            <td>
                                                <button class="btn-status" :style="`background: ${data.status === 'DONE' ? '#00C814' : data.status === 'EXPRIED' ? '#EE0000' : '#FFB800'}`">
                                                    {{ data.status === "DONE" ? 'Đã thanh toán' : data.status === "EXPRIED" ? 'Quá hạn' : "Chờ" }}
                                                </button>
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                        </b-card>
                        <div class="card-order-lookup-footer mt-20px">
                            <div class="btn-pay-all" @click="$bvModal.show('modal-select-payment')">
                                Tất toán
                            </div>
                            <div class="btn-pay" @click="$bvModal.show('modal-select-payment')">
                                Thanh toán
                            </div>
                        </div>
                    </div>
                </b-card>
            </template>
        </div>
        <template v-if="pageStep === 3">
            <div class="card-order-lookup-container">
                <b-card class="complete card-order-lookup-success">
                    <div class="col-md-12">
                        <div class="pb-3 pt-3 w-100 text-center">
                            <h2 class="huge-text" style="color: #424242">
                                <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756"
                                        stroke="#40E108" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <br />
                                <span class="text-thanks">
                                    Cảm ơn bạn đã thanh toán thành công
                                </span>
                                <br/>
                                <div class="mt-18px text-thanks-info">
                                    Quý Khách sẽ nhận được tin nhắn thông tin
                                    về số điện thoại
                                </div>
                            </h2>
                        </div>
                        <b-card>
                            <div class="p-0">
                                <div class="detail-order-isp p-2">
                                    <b-row class="pb-1">
                                        <b-col cols="6" class="h2 text" style="color: #333333">Mã khoản trả góp:</b-col>
                                        <b-col cols="6" class="pl-0 h1 text-right text-primary" style="font-size: 1.6rem; font-weight: 700">
                                            iTel12345
                                        </b-col>
                                    </b-row>
                                    <b-row class="pt-0 pb-1">
                                        <b-col cols="6" class="h2 text" style="color: #333333">Số thuê bao trả góp:</b-col>
                                        <b-col cols="6" class="h1 text-right" style="font-size: 1.6rem;color: black">
                                        09xxxx9999
                                        </b-col>
                                    </b-row>
                                    <b-row class="pt-0 pb-1">
                                        <b-col cols="6" class="h2 text" style="color: #333333">Họ và tên chủ thuê bao:</b-col>
                                        <b-col cols="6" class="h1 text-right" style="font-size: 1.6rem;color: black">
                                        Nguyễn Văn A
                                        </b-col>
                                    </b-row>
                                    <b-row class="pt-0 pb-1 border-bottom">
                                        <b-col cols="6" class="h2 text" style="color: #333333">Số tiền đã thanh toán</b-col>
                                        <b-col cols="6" class="h1 text-right" style="font-size: 1.6rem;color: black">
                                        1.234.567đ
                                        </b-col>
                                    </b-row>
                                    <b-row class="pt-1">
                                        <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #000000">Người mua hàng:</b-col>
                                        <b-col cols="12" class="h2 text-isp-info" style="color: #000000">Phạm Đức Trung</b-col>
                                        <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #000000">Số điện thoại liên hệ:</b-col>
                                        <b-col cols="12" class="h2 text-isp-info" style="color: #000000">0909999****</b-col>
                                        <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #000000">Email:</b-col>
                                        <b-col cols="12" class="h2 text-isp-info" style="color: #000000">ductrung11***@gmail.com</b-col>
                                        <b-col cols="12" class="b2c-price-texts mt-1 text" tyle="color: #000000">Địa chỉ nhận hàng:</b-col>
                                        <b-col cols="12" class="h2 text-isp-info" style="color: #000000">B018, The Manor, Mễ Trì, Hà Nội</b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-card>
                        <b-row class="mt-18px">
                            <b-col cols="12" class="h2 text text-center" style="font-size: 1.6rem;color: #424242; margin-bottom: 0">
                                Xem thông tin đơn hàng 
                                <a href="#" class="text-primary text-decoration-underline"
                                        style="font-style: italic" target="_blank">tại đây</a></b-col>
                        </b-row>
                        <SurveyCES phone="01234567809" orderId="123456789"/>
                    </div>
                </b-card>
            </div>
        </template>
        <b-modal body-class="modal-select-payment" size="lg" hide-footer hide-header id="modal-select-payment" title="" centered>
            <div>
                <div class="d-flex justify-content-start align-items-center gap-15px">
                    <img src="../assets/images/lookup/payment.png" alt="" />
                    <span class="text-tittle">
                        Hình thức thanh toán
                    </span>
                </div>
                <div class="mt-23px payment-container">
                    <div :class="`payment-card ${paymentMethod == 'VNPAY' ? 'active' : ''}`" @click="paymentMethod = 'VNPAY'">
                        <label class="checkbox-container">
                            <input type="checkbox" :checked="paymentMethod == 'VNPAY'" @click="$event.preventDefault()">
                            <span class="checkmark"></span>
                        </label>
                        <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                            <div>
                                <div class="text-payment-info">
                                    Thanh toán qua VNPAY
                                </div>
                                <div class="text-payment-description mt-3px">
                                    Thanh toán bằng QR code
                                </div>
                            </div>
                            <img src="../assets/images/lookup/vnpay.svg" alt="" />
                        </div>
                    </div>
                    <div :class="`payment-card mt-15px ${paymentMethod == 'BANK' ? 'active' : ''}`" @click="paymentMethod = 'BANK'">
                        <label class="checkbox-container">
                            <input type="checkbox" :checked="paymentMethod == 'BANK'" @click="$event.preventDefault()">
                            <span class="checkmark"></span>
                        </label>
                        <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                            <div>
                                <div class="text-payment-info">
                                    Qua thẻ ATM
                                </div>
                                <div class="text-payment-description mt-3px">
                                    Có hỗ trợ Internet Banking
                                </div>
                            </div>
                            <img src="../assets/images/lookup/atm.svg" alt="" />
                        </div>
                    </div>
                    <div :class="`payment-card mt-15px ${paymentMethod == 'INTCARD' ? 'active' : ''}`" @click="paymentMethod = 'INTCARD'">
                        <label class="checkbox-container">
                            <input type="checkbox" :checked="paymentMethod == 'INTCARD'" @click="$event.preventDefault()">
                            <span class="checkmark"></span>
                        </label>
                        <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                            <div>
                                <div class="text-payment-info">
                                    Qua thẻ thanh toán quốc tế
                                </div>
                                <div class="text-payment-description mt-3px">
                                    Thanh toán qua thẻ thanh toán quốc tế
                                </div>
                            </div>
                            <img src="../assets/images/lookup/visa.svg" alt="" />
                        </div>
                    </div>
                    <div :class="`payment-card mt-15px ${paymentMethod == 'ZALO' || paymentMethod == 'MOMO' ? 'active' : ''}`">
                        <label class="checkbox-container">
                            <input type="checkbox" :checked="paymentMethod == 'ZALO' || paymentMethod == 'MOMO'" @click="$event.preventDefault()">
                            <span class="checkmark"></span>
                        </label>
                        <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                            <div>
                                <div class="text-payment-info">
                                   Thanh toán qua ví điện tử
                                </div>
                                <div class="text-payment-description mt-3px">
                                    Thanh toán qua ZaloPay, Momo
                                </div>
                            </div>
                            <img src="../assets/images/lookup/phone.svg" alt="" />
                        </div>
                        <div class="wallet-info-list">
                            <div class="wallet-info-container" @click="paymentMethod = 'MOMO'">
                                <div class="wallet-info-container-img">
                                    <img src="../assets/images/lookup/momo.svg" alt="" />
                                </div>
                                <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                                    <span class="wallet-info-name">
                                        Ví điện tử Momo
                                    </span>
                                    <img src="../assets/images/lookup/tick.png" alt="" v-if="paymentMethod == 'MOMO'"/>
                                </div>
                            </div>
                            <div class="wallet-info-container" @click="paymentMethod = 'ZALO'">
                                <div class="wallet-info-container-img">
                                    <img src="../assets/images/lookup/zalo.svg" alt="" />
                                </div>
                                <div class="d-flex justify-content-between align-items-center gap-8px w-auto flex-auto">
                                    <span class="wallet-info-name">
                                        Ví điện tử ZaloPay
                                    </span>
                                    <img src="../assets/images/lookup/tick.png" alt="" v-if="paymentMethod == 'ZALO'"/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="mt-18px modal-select-payment-footer">
                    <div class="btn-back" @click="$bvModal.hide('modal-select-payment')">
                        Quay lại
                    </div>
                    <div class="btn-continue" @click="$bvModal.hide('modal-select-payment'); pageStep = 3">
                        Tiếp tục
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BCard,
    BRow,
    BCol
} from "bootstrap-vue"
import SurveyCES from "@/components/SurveyCES.vue"
export default {
    components: {
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BButton,
        BCard,
        BRow,
        BCol,
        SurveyCES
    },
    data() {
        return {
            pageStep: 3,
            paymentData: [
                {
                    period: "00",
                    amount: 1000000,
                    deadline: "Trả trước",
                    code: "TC1234-00",
                    status: "DONE"
                },
                {
                    period: "01",
                    amount: 1000000,
                    deadline: "08h00 08/03/2023",
                    code: "TC1234-00",
                    status: "EXPRIED"
                },
                {
                    period: "02",
                    amount: 1000000,
                    deadline: "08h00 08/04/2023",
                    code: "TC1234-00",
                    status: "WAITTING"
                },
                {
                    period: "03",
                    amount: 1000000,
                    deadline: "08h00 08/05/2023",
                    code: "TC1234-00",
                    status: "WAITTING"
                },
                {
                    period: "04",
                    amount: 1000000,
                    deadline: "08h00 08/06/2023",
                    code: "TC1234-00",
                    status: "WAITTING"
                }
            ],
            paymentMethod: "VNPAY"
        }
    },
    mounted(){

    },
    methods: {

    }
}
</script>
<style lang="scss">
.text-decoration-underline{
    text-decoration: underline;
}
.modal-select-payment{
    padding: 24px;
    .text-tittle{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #404040;
    }
    .payment-container{
        width: 380px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        .payment-card{
            padding: 14px 19px;
            background: #FFFFFF;
            border: 1px solid #DADADA;
            border-radius: 10px;
            display: flex;
            gap: 15px;
            align-items: center;
            flex-wrap: wrap;
            cursor: pointer;
            &.active{
                background: #FFF6F6;
                border: 1px solid #ED1F24;
            }
            .checkbox-container{
                -webkit-appearance: checkbox;
                height: 17px;
                width: 17px;
            }
            .checkbox-container {
                display: block;
                position: relative;
                // padding-left: 35px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
            .checkbox-container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 17px;
                width: 17px;
                background-color: #FFFFFF;
                border-radius: 50%;
                border: 1.2px solid #BBBBBB;
            }
            .checkbox-container:hover input ~ .checkmark {
                background-color: #FFFFFF;
            }
            .checkbox-container input:checked ~ .checkmark {
                border: none;
                background-color: #ED1F24;
            }
            .checkmark:after {
            content: "";
                position: absolute;
                display: none;
            }
            .checkbox-container input:checked ~ .checkmark:after {
                display: block;
            }
            .checkbox-container .checkmark:after {
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: #FFFFFF;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
            .text-payment-info{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #4B4B4B;
            }
            .text-payment-description{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 300;
                font-size: 9px;
                line-height: 11px;
                color: #4B4B4B;
            }
            .wallet-info-list{
                width: 100%;
                padding: 0 34px;
            }
            .wallet-info-container{
                width: 100%;
                display: flex;
                gap: 11px;
                align-items: center;
                cursor: pointer;
                padding-right: 8px;
                &:first-child{
                    padding-bottom: 8px;
                    border-bottom: 1px solid #DADADA;
                }
                &:last-child{
                    padding-top: 12px;
                }
                .wallet-info-container-img{
                    width: 51px;
                    display: flex;
                    justify-content: center;
                }
                .wallet-info-name{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    color: #4B4B4B;
                }
            }
        }
    }
    .modal-select-payment-footer{
        display: flex;
        flex: 1 1;
        justify-content: end;
        align-items: center;
        gap: 8px;
        width: 380px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        .btn-back{
            width: 151.22px;
            height: 37.09px;
            background: #FFFFFF;
            border: 1px solid #ED1F24;
            border-radius: 19.972px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 12.4367px;
            line-height: 13px;
            color: #ED1F24;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .btn-continue{
            width: 151.22px;
            height: 37.09px;
            background: #ED1F24;
            border-radius: 20.8347px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 12.4367px;
            line-height: 8px;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
@media screen and (max-width: 768px){
    .modal-select-payment{
        .modal-select-payment-footer{
            width: 100%;
            .btn-back{
                width: 100%;
                flex: 50%;
            }
            .btn-continue{
                width: 100%;
                flex: 50%;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.mt-26px{
    margin-top: 26px;
}
.mt-60px{
    margin-top: 26px;
}
.mt-3px{
    margin-top: 3px;
}
.mt-5px{
    margin-top: 5px;
}
.mt-10px{
    margin-top: 10px;
}
.mt-11px{
    margin-top: 11px;
}
.mt-15px{
    margin-top: 15px;
}
.mt-18px{
    margin-top: 18px;
}
.mt-32px{
    margin-top: 32px;
}
.mt-20px{
    margin-top: 20px;
}
.mt-23px{
    margin-top: 23px;
}
.mt-28px{
    margin-top: 32px;
}
.gap-8px{
    gap: 8px;
}
.gap-15px{
    gap: 15px;
}
.flex-auto{
    flex: auto;
}
.complete {
  margin-left: auto;
  margin-right: auto;
  max-width: 562px;
}
.complete .card-body {
  padding: 0 !important;
}
.b2c-pc-header {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-bottom: 1px solid #dadada;
  width: 100%;
}
.b2c-pc-header {
  display: flex;
}
.b2c-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
}
.order-lookup-page-content{
    padding: 50px 0;
    .page-lookup-card{
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }
    .text-lookup-tittle{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 11px;
        color: #424242;
    }
    .lookup-group-input{
        display: flex;
        gap: 11px;
        .search-input-group{
            position: relative;

            .search-img{
                position: absolute;
                top: 50%;
                left: 1rem;
                transform: translateY(-50%);
                z-index: 20;
            }
            .form-control{
                padding-left: calc(24px + 2rem);

                background: #FFFFFF;
                border: 1px solid #CFCFCF;
                border-radius: 42px !important;

                font-family: 'Inter';
                font-style: italic;
                font-weight: 400;
                font-size: 14.057px;
                line-height: 17px;
                color: #000000;

                &::placeholder{
                    font-family: 'Inter';
                    font-style: italic;
                    font-weight: 400;
                    font-size: 14.057px;
                    line-height: 17px;
                    color: #9E9E9E;
                }
            }
        }
        .btn-lookup{
            height: 50px;
            width: 160px;

            display: flex;
            justify-content: center;
            align-items: center;
            background: #FF0000;
            border-radius: 42px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 13.1637px;
            line-height: 16px;
            color: #FFFFFF;
        }
    }
    .card-order-lookup{
        .text-next-payment-date{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            color: #4E4E4E;
        }
        .text-payment-date{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #4E4E4E;
        }
        .text-payment-total{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 42px;
            color: #000000;
        }
        .card{
            box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
            margin-bottom: 0;
            padding: 22px;
        }
        .text-price-tittle{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #808080;
        }
        .text-price{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #313131;
        }
        .progress-bar{
            position: relative;
            background: #F0F0F0;
            border-radius: 38px;
            width: 100%;
            height: 10px;
            .progress-track{
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background: #EA0029;
                border-radius: 38px;
                height: 10px;
            }
        }
        .card-info{
            .card-info-header{
                display: flex;
                gap: 14.5px;
                align-items: center;

                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #404040;
            }
            .card-info-content{
                padding-left: 53.5px;
                .card-info-content-row{
                    padding-bottom: 8px;
                    border-bottom: 1px solid #E7E7E7;
                    margin-left: 0;
                    margin-right: 0;
                    &:last-child{
                        border-bottom: none;
                    }
                    .tittle{
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 12px;
                        color: #8B8B8B;
                    }
                    .value{
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 16px;
                        color: #212121;
                    }
                }
            }
        }
        table {
            width: 100%;

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            text-align: center;

            color: #212121;

            tr {
                border-bottom: 1px solid #E7E7E7;
                th{
                    padding-bottom: 10px;
                    padding-left: 5px;
                    padding-right: 5px;
                }
                td{
                    padding: 17.4px 5px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    color: #212121;

                    vertical-align: middle;
                }

                .btn-status{
                    width: 100%;
                    background: #00C814;
                    border-radius: 15.2149px;
                    height: 19.19px;

                    white-space: nowrap;
                    padding: 0 3px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 8px;
                    line-height: 6px;
                    color: #FFFFFF;
                }
            }

            // tr:nth-child(even):not(.tr-content-button) th {
            //     background-color: #FFECEC;
            //     border-right-color: #FFECEC;
            // }

            // tr th:first-child {
            //     padding-left: 33px;
            // }

            // .tr-content th {
            //     padding: 19px 10px 17px 10px;
            // }

            // .tr-content-button th {
            //     padding-top: 23px;
            //     padding-bottom: 46px;
            //     align-content: center;
            //     text-align: center;
            // }
        }
        .card-order-lookup-footer{
            display: flex;
            flex: 1 1;
            justify-content: end;
            align-items: center;
            gap: 8px;
            .btn-pay-all{
                width: 151.22px;
                height: 37.09px;
                background: #FFFFFF;
                border: 1px solid #ED1F24;
                border-radius: 19.972px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 12.4367px;
                line-height: 13px;
                color: #ED1F24;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .btn-pay{
                width: 151.22px;
                height: 37.09px;
                background: #ED1F24;
                border-radius: 20.8347px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 12.4367px;
                line-height: 8px;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
}
.card-order-lookup-container{
    padding: 50px 0;
}
.card-order-lookup-success{
    .text-thanks{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 41px;
        color: #424242;
    }
    .text-thanks-info{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #424242;
        max-width: 320px;
        white-space: break-spaces;
        margin-left: auto;
        margin-right: auto;
    }
    .card{
        box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
        margin-bottom: 0;
        padding: 22px;
    }
}
@media screen and (max-width: 768px){
    .b2c-pc-header{
        display: none;
    }
    .order-lookup-page-content{
        padding-left: 15px;
        padding-right: 15px;
        .lookup-group-input{
            flex-direction: column;
            gap: 20.25px;

            .btn-lookup{
                height: 50px;
                width: 184px;
                background: #ED1F24;
                border-radius: 10px;
            }
        }
        .card-order-lookup{
            background: rgba(242, 242, 242, 1);
            border: none !important;
            border-radius: 0 !important;
            box-shadow: none;
            .card-info{
                .card-info-content{
                    padding: 0;
                }
            }
            .card-info-table{
                padding-left: 0;
                padding-right: 0;
                .card-info-header{
                    padding-left: 22px;
                    padding-right: 22px;
                }
            }
            .card-order-lookup-footer{
                .btn-pay-all{
                    width: 100%;
                    flex: 50%;
                }
                .btn-pay{
                    width: 100%;
                    flex: 50%;
                }
            }
        }
    }
    .card-order-lookup-container{
        padding: 0;
    }
    .card-order-lookup-success{
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none; 
        margin-bottom: 0;
        padding-bottom: 39px;
    }
}
</style>